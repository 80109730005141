import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Button } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Column, Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';
import { PageHeader } from 'src/layouts/PageHeader';
import { SEO } from 'src/containers/SEO';

export default function NotFound({ children, title, subtitle, indexUrl }) {
  return (
    <>
      <SEO title="Oops! This page couldn’t be found" meta={[{ name: 'robots', content: 'noindex' }]} />
      <PageHeader>
        <Section>
          <Grid verticalAlign="center" columns="3fr 2fr">
            <Column centered>
              <StaticImage
                placeholder="none"
                layout="constrained"
                src="../images/404.jpg"
                alt="Sara, the Rasa mascot, floating in space and being confused"
              />
            </Column>
            <Column>
              <h1>{title ?? `Oops!`}</h1>
              <h3>{subtitle ?? `This page couldn’t be found.`}</h3>
              <InlineList small>
                <li>
                  <Button to={indexUrl || '/'} icon={<Icon icon={['fas', 'arrow-left']} />}>
                    Go back
                  </Button>
                </li>
                <li>
                  <Button to="/" secondary>
                    Visit Rasa.com
                  </Button>
                </li>
              </InlineList>
            </Column>
          </Grid>
        </Section>
      </PageHeader>
      {children}
    </>
  );
}
